import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Logo from "../../components/logo"

const Language = "de-DE"

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" lang={Language} />
        <div className="">
        <Logo className="mx-auto h-auto w-56 pt-8" route="https://candide.berlin/startseite" />
        <div className="max-w-xl mx-auto text-center ">

        <h1 className="mt-20 text-4xl font-bold">PAGE NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <a href="https://candide.berlin" className="mt-4 block text-canred">Return Home</a>
        </div>
        </div>
  </Layout>

)

export default NotFoundPage
